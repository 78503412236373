import { WebSDK } from '@dealroadshow/jsbro/OTel/sdk-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import isServer from '@/Framework/Router/Next/isServer';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import env from '@/Framework/env/env';
import { CustomSpanProcessor } from '@/Framework/OTel/CustomSpanProcessor';

const createOTelWebConfig = ({ entry }: { entry: string }) => {
  if (!isServer() && env('OTEL_ENABLED') === 'true') {
    const traceExporter = env('OTEL_PUBLIC_URL') &&
                          env('OTEL_PUBLIC_URL').startsWith('http') // hack to make it work with our env substitution
      ? new OTLPTraceExporter({
        url: `${ env('OTEL_PUBLIC_URL') }/v1/traces`,
        headers: {
          Authorization: `Basic ${ env('OTEL_PUBLIC_AUTH_BASIC') }`,
          'Content-Type': 'text/plain',
        },
      }) : new ConsoleSpanExporter();

    const sdk = new WebSDK({
      serviceName: `ws-frontend-${ entry }-web`,
      serviceVersion: env('SENTRY_RELEASE'),
      contextManager: new ZoneContextManager(), // required to keep track of active otel context
      traceExporter,
      // @ts-ignore
      spanProcessors: [new CustomSpanProcessor(traceExporter)],
    });

    sdk.start();
  }
};

export default createOTelWebConfig;
