import { ReactNode } from 'react';
import OTelTraceContextProvider, {
  useOTelTraceContext,
  IProps as ContextProps, getName,
} from '@/Framework/OTel/OTelTraceContext';

const OTelInstrument = ({ children }: { children: ReactNode }) => {
  const { span } = useOTelTraceContext();
  const element = children;
  span.end();
  return element;
};

export const OTelInstrumentation = ({
  children,
  name,
  ...otherProps
}: { children: ReactNode } & ContextProps) => {
  if (!name) {
    name = getName(children);
  }
  // Creating a new OTelTraceContextProvider every time is not necessary.
  // Context is required for keeping nested structure of elements.
  // In case of using single OTelTraceContextProvider, we cannot determine the hierarchy of elements
  // as React may render children in different order.
  return (
    <OTelTraceContextProvider { ...otherProps } name={ name }>
      <OTelInstrument>
        { children }
      </OTelInstrument>
    </OTelTraceContextProvider>
  );
};
