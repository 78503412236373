import { Context } from '@dealroadshow/jsbro/OTel/api';
import * as Sentry from '@sentry/browser';
import { BatchSpanProcessor, Span } from '@opentelemetry/sdk-trace-base';

export class CustomSpanProcessor extends BatchSpanProcessor {
  onStart(_span: Span, _parentContext: Context) {
    const activeSpan = Sentry.getActiveSpan();

    // undefined should be a string, otherwise it will not be displayed in OTel
    const traceId = activeSpan ? activeSpan.spanContext().traceId : 'undefined';
    _span.setAttributes({
      'sentry.traceId': traceId,
    });

    super.onStart(_span, _parentContext);
  }
}
