import '../instrumentation-web';
import '@/Framework/browser/polyfill';
import { AppProps } from 'next/app';
import React, { useState, useEffect } from 'react';
import { Store } from 'redux';
import Head from 'next/head';
import GlobalStyles, { styles } from '@/users/ui/common/GlobalStyles';
import Container from '@/Framework/DI/Container';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import Bootstrap, { healthCheck, wrapper } from '@/Framework/Bootstrap';
import ApplicationWrp from '@/Framework/UI/Templates/ApplicationWrp';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import { Provider } from 'react-redux';
import reducer from '@/users/application/reducer';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import config from '@/Framework/config';
import TenantContext from '@/Framework/Tenant/TenantContext';
import getRouter from '@/users/infrastructure/next/Router';
import { OTelInstrumentation } from '@/Framework/OTel/OTelInstrumentation';

const App = ({ Component, pageProps, container, store }: AppProps<IGlobalProps> & {
  container: Container,
  store: Store,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // @ts-ignore
  return (
    <>
      {
        isMounted && (
          <GlobalStyles>
            <Head>
              <title />
              <meta charSet="utf-8" />
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta
                httpEquiv="Cache-Control"
                content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
              />
              <meta httpEquiv="Pragma" content="no-store" />
              <meta httpEquiv="Expires" content="0" />
              <meta
                content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
                name="viewport"
              />
              <meta name="HandheldFriendly" content="true" />
            </Head>
            { /* @ts-ignore */ }
            <ErrorBoundary fallback={ ApplicationFallback }>
              <Bootstrap
                session={ pageProps?.session }
                container={ container }
                store={ store }
                cookies={ pageProps?.cookies }
              >
                <GlobalProps { ...pageProps }>
                  <ApplicationWrp className={ styles.body }>
                    <ContentWrp>
                      <Provider store={ store }>
                        { /* @ts-ignore */ }
                        <TenantContext tenant={ getRouter().query.tenant || config.tenant.dmPortal.code }>
                          <OTelInstrumentation>
                            <Component { ...pageProps } />
                          </OTelInstrumentation>
                        </TenantContext>
                      </Provider>
                    </ContentWrp>
                  </ApplicationWrp>
                </GlobalProps>
              </Bootstrap>
            </ErrorBoundary>
          </GlobalStyles>
        )
      }
    </>
  );
};

export default healthCheck(wrapper(reducer).withRedux(App));
